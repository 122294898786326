import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { ScheduleType } from '../../../types/WeeklySchedule';

interface IScheduleTypeSelectionProps {
    type: ScheduleType;
    setType: (val: ScheduleType) => void;
}

export const ScheduleTypeSelection = ({ type, setType }: IScheduleTypeSelectionProps) => {
    const getTypes = [ScheduleType.Normal, ScheduleType.TwentyFourHour, ScheduleType.OnDemand];
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">Type</FormLabel>
            <RadioGroup value={type} onChange={(d, newVal) => setType(newVal as ScheduleType)}>
                {getTypes.map((type) => (
                    <FormControlLabel key={type} value={type} control={<Radio />} label={type} />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
