import { FormControlLabel, FormGroup, FormGroupProps, Switch, Typography } from '@material-ui/core';
import React, { useState } from 'react';

interface IToggleSwitchProps extends FormGroupProps {
    value: boolean | undefined;
    setValue: (val: boolean) => void;
    label?: string;
    shouldUpdate?: boolean;
    style?: any;
}

export const ToggleSwitch = ({ value, setValue, label, shouldUpdate, style }: IToggleSwitchProps) => {
    const [internalValue, setInternalValue] = useState(value || false);

    return (
        <FormGroup row style={{ ...{ padding: 5 }, ...style }}>
            <FormControlLabel
                control={
                    <Switch
                        checked={value}
                        onChange={(event, checked) => {
                            setValue(checked);
                            setInternalValue(checked);
                        }}
                    />
                }
                label={<Typography color={internalValue ? 'secondary' : 'textSecondary'}>{label}</Typography>}
            />
        </FormGroup>
    );
};
