import AWS, { AWSError } from 'aws-sdk';
import { ConfigurationOptions } from 'aws-sdk/lib/config';
import { AssumeRoleResponse } from 'aws-sdk/clients/sts';
import { GetIdResponse, IdentityId, LoginsMap } from 'aws-sdk/clients/cognitoidentity';

const SESSION_NAME = 'SchedulerV2';

const handleError = (error: any, resolve: (res: ConfigurationOptions | undefined) => void) => {
    console.error('An error occurred while assuming role.');
    console.error(error);
    resolve(undefined);
};

interface IGetRolesParams {
    roleArn: string | undefined;
    sessionName?: string;
    roleToAssumeFrom?: ConfigurationOptions;
}

export const GetRole = async ({
    roleArn,
    sessionName = SESSION_NAME,
    roleToAssumeFrom,
}: IGetRolesParams): Promise<ConfigurationOptions | undefined> => {
    if (!roleArn) {
        return;
    }
    return new Promise<ConfigurationOptions>((resolve, reject) => {
        try {
            const sts = new AWS.STS(roleToAssumeFrom);

            sts.assumeRole(
                { RoleArn: roleArn, RoleSessionName: sessionName },
                (err: AWSError, data: AssumeRoleResponse) => {
                    if (err || !data || !data.Credentials) {
                        return handleError(err, resolve);
                    }

                    resolve({
                        credentials: {
                            accessKeyId: data.Credentials.AccessKeyId,
                            secretAccessKey: data.Credentials.SecretAccessKey,
                            sessionToken: data.Credentials.SessionToken,
                        },
                    });
                }
            );
        } catch (error) {
            handleError(error, resolve);
        }
    });
};

interface IGetRoleFromTokenParams {
    identityId: IdentityId;
    Logins: LoginsMap;
}

export const GetIdFromIdentityPoolToken = (idToken: string): Promise<GetIdResponse> => {
    return new Promise<GetIdResponse>((resolve, reject) => {
        const cognitoIdentity = new AWS.CognitoIdentity({ region: 'us-east-1' });

        const config = {
            IdentityPoolId: 'us-east-1:7e500056-f86a-459f-9e93-28089e6650a1',
            Logins: {
                'cognito-idp.us-east-1.amazonaws.com/us-east-1_ppTvZYJ8H': idToken,
            },
        };
        cognitoIdentity.getId(config, async (err, data) => {
            if (err || !data.IdentityId) {
                console.error('an error occurred while getting id');
                console.error(data);
                console.error(err);
                reject();
                return;
            }

            resolve(data);
        });
    });
};

export const GetRoleFromToken = async ({
    identityId,
    Logins,
}: IGetRoleFromTokenParams): Promise<ConfigurationOptions | undefined> => {
    return new Promise<ConfigurationOptions | undefined>((resolve, reject) => {
        const cognitoIdentity = new AWS.CognitoIdentity({ region: 'us-east-1' });
        cognitoIdentity.getCredentialsForIdentity({ IdentityId: identityId, Logins }, (credIdError, credIdData) => {
            if (credIdError) {
                console.error('An error occurred while retrieving credentials.');
                console.error(credIdError);
                reject();
                return;
            }

            resolve({
                credentials: {
                    accessKeyId: credIdData?.Credentials?.AccessKeyId || '',
                    secretAccessKey: credIdData?.Credentials?.SecretKey || '',
                    sessionToken: credIdData?.Credentials?.SessionToken,
                },
            });
        });
    });
};
