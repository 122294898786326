import Lambda from 'aws-sdk/clients/lambda';

export const getApplications = async (userEmail: string) : Promise<Set<string>> => {
    const response = await new Lambda().invoke({
        // TODO - Parameterize it for stages
        FunctionName: "papi-dev-fetchAWSAccounts",
        Payload: JSON.stringify({ email: userEmail })
    }).promise()

    if (response.Payload && typeof response.Payload === 'string') {
        return new Set(JSON.parse(response.Payload))
    } else {
        throw new Error('Unknown response type')
    }
}

