import { getApplications } from '../AWS/Lambda';
import { IAccount } from '../../types/IAccount';
import { sortAccounts } from '../TableFiltering';
import { GetRole } from '../AWS/STS';
import { readAccountsTable, validAccountId, IGetAccountsParams } from './GetAccounts'
import { ConfigurationOptions } from 'aws-sdk/lib/config';

const SHARED_SERVICES_READER_ROLE ="arn:aws:iam::640315046644:role/scheduler-ui-get-accounts"
const AWS_ACCOUNTS_TABLE = 'aws-accounts'


const getRole = (accountNumber: string) => {
    const rolePrefix = 'aws2.0'
    return `arn:aws:iam::${accountNumber}:role/protected/${rolePrefix}-scheduler`;
};

const mapAccounts = (input: any): IAccount => ({
        Id: input.id?.S,
        Name: input.alias?.S,
        Owner: input.owners?.join(', '), // Need to determine impact
        Status: 'created',
        Role: getRole(input.id?.S),
        Platform: 'AWS',
        Type: 'smartAWS2.0',
        EC2Instances: [],
        RDSInstances: [],
        Environment: input.env,
        Regions: ['us-east-1', 'eu-central-1', 'ap-east-1'],
        IsGXP: input.gxp?.BOOL,
    })

const getAccountsFromDynamo = async (amcaSchedulerRole: ConfigurationOptions) => {
    const readerRole = await GetRole({
        roleArn: SHARED_SERVICES_READER_ROLE,
        roleToAssumeFrom: amcaSchedulerRole
    });
    const accountsTableEntries = await readAccountsTable(AWS_ACCOUNTS_TABLE, readerRole) || [];
    return accountsTableEntries.map(mapAccounts)
}


export const getAWS2Accounts = async ({ amcaSchedulerRole, cwid }: IGetAccountsParams) : Promise<IAccount[]> => {
    const [papiApps, accounts] = await Promise.all([
        getApplications(cwid || ""),
        getAccountsFromDynamo(amcaSchedulerRole)
    ])

    const openAccounts = accounts.filter((account) =>
            papiApps.has(account.Id) &&
            account.Status === 'created' &&
            account.Id.match(validAccountId) &&
            account.Regions.length > 0 &&
            !account.IsGXP && !!account.Role
        ).sort(sortAccounts);

    return openAccounts;

}

