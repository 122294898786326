import MaterialTable from 'material-table';
import React, { useEffect, useMemo, useState } from 'react';
import { getStateColor } from '../../utils/GetStateColor';
import { getFilterTags, getTableOptions } from '../../utils/TableFiltering';
import { ScheduleTagBadge } from '../ScheduleTagBadge';
import { useAppContext } from '../../context/AppContext';
import { GetRole } from '../../utils/AWS/STS';
import { IInstanceRow } from '../../types/IInstanceRow';
import { GetInstancesForTableFromAWS } from './utils/GetInstancesForTableFromAWS';

export const InstanceTable = () => {
    const [instanceRows, setInstanceRows] = useState<IInstanceRow[]>([]);
    const context = useAppContext();
    const account = context.selectedAccount;
    const setIsLoading = context.setIsLoading;
    const schedulerAccountRole = context.schedulerAccountRole;
    const setLoadingMessage = context.setLoadingMessage;
    const refreshTableTrigger = context.refreshTableTrigger;

    const service = context.selectedService;
    useEffect(() => {
        (async () => {
            if (!schedulerAccountRole) {
                return;
            }

            if (!account) {
                return;
            }

            setIsLoading(true);
            const role = await GetRole({
                roleArn: account?.Role,
                roleToAssumeFrom: schedulerAccountRole,
            });

            if (!role) {
                console.error('An error occurred while assuming role.');
                setInstanceRows([]);
                setIsLoading(false);
                return;
            }

            const result = await GetInstancesForTableFromAWS({ role, account, setLoadingMessage, service });
            setInstanceRows(result);
            setIsLoading(false);
            setLoadingMessage(undefined);
        })();
    }, [account, schedulerAccountRole, setIsLoading, setLoadingMessage, service]);

    return useMemo(() => {
        refreshTableTrigger.toString(); // no-op to stop react error about unnecessary dependency
        if (service === 'ec2')
            return (
                <MaterialTable
                    title={'EC2 Instances'}
                    options={getTableOptions(instanceRows)}
                    key={`${new Date().toUTCString()}-${!!instanceRows ? 'Initialized' : 'Uninitialized'}`}
                    columns={[
                        { title: 'Account', field: 'AccountName' },
                        { title: 'Region', field: 'Region' },
                        { title: 'Instance Name', field: 'Name' },
                        {
                            title: 'Instance Id',
                            field: 'Id',
                        },
                        {
                            title: 'State',
                            field: 'State',
                            lookup: getFilterTags(instanceRows?.map((row) => row.State || '') || []),
                            render: (row) => <span style={{ color: getStateColor(row.State) }}>{row.State}</span>,
                        },
                        {
                            title: 'Schedule 1',
                            render: (row) => <ScheduleTagBadge Row={row} Index={0}/>,
                        },
                        {
                            title: 'Schedule 2',
                            render: (row) => <ScheduleTagBadge Row={row} Index={1}/>,
                        },
                        {
                            title: 'Schedule 3',
                            render: (row) => <ScheduleTagBadge Row={row} Index={2}/>,
                        },
                    ]}
                    data={instanceRows}
                />
            );

        if (service === 'rds')
            return (
                <MaterialTable
                    title={'RDS Instances'}
                    options={getTableOptions(instanceRows)}
                    key={`${new Date().toUTCString()}-${!!instanceRows ? 'Initialized' : 'Uninitialized'}`}
                    columns={[
                        { title: 'Account', field: 'AccountName' },
                        { title: 'Region', field: 'Region' },
                        { title: 'Instance ID', field: 'Name' },
                        {
                            title: 'Size',
                            field: 'Size',
                        },
                        {
                            title: 'State',
                            field: 'State',
                            lookup: getFilterTags(instanceRows?.map((row) => row.State || '') || []),
                            render: (row) => <span style={{ color: getStateColor(row.State) }}>{row.State}</span>,
                        },
                        {
                            title: 'Schedule',
                            render: (row) => <ScheduleTagBadge Row={row} Index={0}/>,
                        },
                        {
                            title: 'Schedule 2',
                            render: (row) => <ScheduleTagBadge Row={row} Index={1}/>,
                        },
                        {
                            title: 'Schedule 3',
                            render: (row) => <ScheduleTagBadge Row={row} Index={2}/>,
                        },
                    ]}
                    data={instanceRows}
                />
            );

        throw new Error('Not a valid service');
    }, [instanceRows, refreshTableTrigger, service]);
};
