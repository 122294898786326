import { WeeklySchedule } from '../types/WeeklySchedule';
import AWS from 'aws-sdk';
import { IAccount } from '../types/IAccount';
import { GetRole } from './AWS/STS';
import { ConfigurationOptions } from 'aws-sdk/lib/config';

export const SCHEDULE_TAG = 'ScheduleV2';

export interface IScheduleTagInstancePair {
    instanceId: string;
    schedule: WeeklySchedule | undefined;
    originalSchedule?: string;
}

export const SetInstanceScheduleTag = async (
    account: IAccount,
    tagInstancePairs: IScheduleTagInstancePair[],
    region: string,
    schedulerAccountRole: ConfigurationOptions,
    service: 'ec2' | 'rds',
    contextIndex: number
): Promise<boolean> => {
    if (tagInstancePairs.length === 0) return true;
    return new Promise<boolean>(async (resolve, reject) => {
        const roleForAccount = await GetRole({
            roleArn: account.Role,
            roleToAssumeFrom: schedulerAccountRole,
        });

        const successes: boolean[] = [];
        const index = contextIndex === 0 ? '' : `_${contextIndex}`
        if (service === 'ec2') {
            const ec2 = new AWS.EC2({ ...roleForAccount, ...{ region } });
            for (const { schedule, instanceId } of tagInstancePairs) {
                if (!!schedule) {
                    const success = await new Promise<boolean>((createTagResolve) =>
                        ec2.createTags(
                            { Resources: [instanceId], Tags: [{ Key: `${SCHEDULE_TAG}${index}`, Value: schedule.toString() }] },
                            (err) => {
                                if (err) {
                                    console.error('An error occurred while creating a tag');
                                    console.error(err);
                                }

                                createTagResolve(!err);
                            }
                        )
                    );

                    successes.push(success);
                } else {
                    const success = await new Promise<boolean>((deleteTagResolve) =>
                        ec2.deleteTags({ Resources: [instanceId], Tags: [{ Key: `${SCHEDULE_TAG}${index}` }] }, (err, data) => {
                            if (err) {
                                console.error('An error occurred while deleting a tag');
                                console.error(err);
                            }
                            deleteTagResolve(!err);
                        })
                    );

                    successes.push(success);
                }
            }
        }

        if (service === 'rds') {
            const rds = new AWS.RDS({ ...roleForAccount, ...{ region } });
            for (const { schedule, instanceId } of tagInstancePairs) {
                if (!!schedule) {
                    const success = await new Promise<boolean>((addTagsResolve) =>
                        rds.addTagsToResource(
                            {
                                ResourceName: instanceId,
                                Tags: [{ Key: `${SCHEDULE_TAG}${index}`, Value: schedule.toRDSFriendlyString() }],
                            },
                            (err) => {
                                if (err) {
                                    console.error('An error occurred while creating a tag');
                                    console.error(err);
                                }

                                addTagsResolve(!err);
                            }
                        )
                    );

                    successes.push(success);
                } else {
                    const success = await new Promise<boolean>((removeTagsResolve) =>
                        rds.removeTagsFromResource({ ResourceName: instanceId, TagKeys: [`${SCHEDULE_TAG}${index}`] }, (err) => {
                            if (err) {
                                console.error('An error occurred while deleting a tag');
                                console.error(err);
                            }

                            removeTagsResolve(!err);
                        })
                    );

                    successes.push(success);
                }
            }
        }

        resolve(successes.every((x) => x));
    });
};
