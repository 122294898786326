import React, { useEffect, useState } from 'react';
import { ScheduleType, WeeklySchedule } from '../types/WeeklySchedule';
import { TwentyFourHourScheduleSettings } from '../components/CreateScheduleModal/TwentyFourHourScheduleSettings/TwentyFourHourScheduleSettings';
import { Box, FormControl, IconButton, Input, InputAdornment, InputLabel, Tooltip } from '@material-ui/core';
import { NormalScheduleSettings } from '../components/CreateScheduleModal/NormalScheduleSettings/NormalScheduleSettings';
import { ScheduleTypeSelection } from '../components/CreateScheduleModal/ScheduleTypeSelector/ScheduleTypeSelection';
import { DaysOfWeek } from '../types/DaysOfWeek';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const DEFAULT_TIMEZONE: string = 'America/New_York';

export const TagGenerator = () => {
    const [scheduleType, setScheduleType] = useState<ScheduleType>(ScheduleType.Normal);
    const [isAllDays, setIsAllDays] = useState(false);
    const [startTime, setStartTime] = useState<string>('07:30');
    const [stopTime, setStopTime] = useState<string>('18:30');
    const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);
    const [startDay, setStartDay] = useState<DaysOfWeek>(DaysOfWeek.Monday);
    const [stopDay, setStopDay] = useState<DaysOfWeek>(DaysOfWeek.Friday);
    const [daysOfWeek, setDaysOfWeek] = useState<DaysOfWeek[]>([
        DaysOfWeek.Monday,
        DaysOfWeek.Tuesday,
        DaysOfWeek.Wednesday,
        DaysOfWeek.Thursday,
        DaysOfWeek.Friday,
    ]);

    const [schedule, setSchedule] = useState<WeeklySchedule>(
        WeeklySchedule.fromNormalConfig(timezone, daysOfWeek, startTime, stopTime)
    );

    useEffect(() => {
        let schedule: WeeklySchedule;
        switch (scheduleType) {
            case ScheduleType.Normal:
                schedule = WeeklySchedule.fromNormalConfig(timezone, daysOfWeek, startTime, stopTime);
                break;
            case ScheduleType.TwentyFourHour:
                schedule = isAllDays
                    ? WeeklySchedule.fromAllDaysTwentyFourHourConfig()
                    : WeeklySchedule.fromTwentyFourHourConfig(timezone, startDay, stopDay);
                break;
            case ScheduleType.OnDemand:
                schedule = WeeklySchedule.onDemand();
                break;
        }

        setSchedule(schedule);
    }, [scheduleType, isAllDays, startTime, stopTime, timezone, startDay, stopDay, daysOfWeek]);

    const copyToClipBoard = (isRDS: boolean) => {
        const el = document.createElement('textarea');
        el.value = isRDS ? schedule.toRDSFriendlyString() : schedule.toString();
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} flexDirection={'row'} padding={2}>
                <ScheduleTypeSelection type={scheduleType} setType={setScheduleType} />
                {scheduleType === ScheduleType.Normal && (
                    <NormalScheduleSettings
                        timezone={timezone}
                        setTimezone={setTimezone}
                        daysOfWeek={daysOfWeek}
                        setDaysOfWeek={setDaysOfWeek}
                        startTime={startTime}
                        setStartTime={setStartTime}
                        stopTime={stopTime}
                        setStopTime={setStopTime}
                    />
                )}

                {scheduleType === ScheduleType.TwentyFourHour && (
                    <TwentyFourHourScheduleSettings
                        timezone={timezone}
                        setTimezone={setTimezone}
                        isAllDays={isAllDays}
                        setIsAllDays={setIsAllDays}
                        startDay={startDay}
                        setStartDay={setStartDay}
                        stopDay={stopDay}
                        setStopDay={setStopDay}
                    />
                )}
            </Box>

            <FormControl style={{ margin: 10 }}>
                <InputLabel htmlFor="ec2TagValue">EC2 Tag Value</InputLabel>
                <Input
                    disabled
                    id="ec2TagValue"
                    value={schedule.toString()}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility">
                                <Tooltip title={'Copy to clipboard'}>
                                    <FileCopyIcon onClick={() => copyToClipBoard(false)} />
                                </Tooltip>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl style={{ margin: 10 }}>
                <InputLabel htmlFor="rdsTagValue">RDS Tag Value</InputLabel>
                <Input
                    disabled
                    id="rdsTagValue"
                    value={schedule.toRDSFriendlyString()}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility">
                                <Tooltip title={'Copy to clipboard'}>
                                    <FileCopyIcon onClick={() => copyToClipBoard(true)} />
                                </Tooltip>
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
        </Box>
    );
};
