import { Backdrop, Box, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { useAppContext } from '../../context/AppContext';

const DEFAULT_MESSAGE = 'Loading';

export const LoadingOverlay = () => {
    const classes = useStyles();
    const context = useAppContext();
    const isInitializing = context.isLoading;
    const loadingMessage = context.loadingMessage;

    return (
        <Backdrop className={classes.backdrop} open={isInitializing}>
            <Box display={'flex'} flexDirection={'column'} flexGrow={1} alignItems={'center'}>
                <Typography variant={'subtitle1'}>{loadingMessage || DEFAULT_MESSAGE}</Typography>
                <LinearProgress style={{ width: 150, marginTop: 7, height: 2 }} />
            </Box>
        </Backdrop>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);
