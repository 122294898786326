import React, { useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { MainScreen } from './screens/MainScreen';
import { getSession, getUserName } from './utils/Cookies';
import {
    expiredTokenEndpoint,
    loginCallBackEndpoint,
    loginEndpoint,
    logoutEndpoint,
    tagGenerator,
} from './RouteNameConstants';
import { LoginCallbackHandler } from './components/LoginCallbackHandler';
import { Login } from './components/Login';
import { AppContext, IAppContext } from './context/AppContext';
import { Logout } from './components/Logout';
import { IEC2InstanceRow } from './types/IEC2InstanceRow';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { IAccount } from './types/IAccount';
import { ConfigurationOptions } from 'aws-sdk/lib/config';
import { TagGenerator } from './screens/TagGenerator';

const theme = createMuiTheme({
    palette: {
        type: 'light',
    },
});

export const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [selectedInstance, setSelectedInstance] = useState<IEC2InstanceRow>();
    const [createScheduleModalOpen, setCreateScheduleModalOpen] = useState(false);
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [schedulerAccountRole, setSchedulerAccountRole] = useState<ConfigurationOptions | undefined>();
    const [amcaSchedulerRole, setAmcaSchedulerRole] = useState<ConfigurationOptions | undefined>();
    const [selectedAccount, setSelectedAccount] = useState<IAccount | undefined>();
    const [accountsDrawerOpen, setAccountsDrawerOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
    const [refreshTableTrigger, setRefreshTableTrigger] = useState({});
    const [selectedService, setSelectedService] = useState<'rds' | 'ec2'>('ec2');
    const [weeklyScheduleIndex, setWeeklyScheduleIndex] = useState(0);

    if (!isAuthenticated && !!getSession()) {
        setIsAuthenticated(true);
    }

    const appContextValue: IAppContext = {
        weeklyScheduleIndex,
        setWeeklyScheduleIndex,
        setIsAuthenticated,
        getUserName,
        selectedInstance,
        setSelectedInstance,
        createScheduleModalOpen,
        setCreateScheduleModalOpen,
        accounts,
        setAccounts,
        schedulerAccountRole: schedulerAccountRole,
        setSchedulerAccountRol: setSchedulerAccountRole,
        amcaSchedulerRole,
        setAmcaSchedulerRole,
        selectedAccount,
        setSelectedAccount,
        accountsDrawerOpen,
        setAccountsDrawerOpen,
        isLoading,
        setIsLoading,
        loadingMessage,
        setLoadingMessage,
        refreshTableTrigger,
        triggerTableRefresh: () => setRefreshTableTrigger({}),
        selectedService,
        setSelectedService,
    };

    return (
        <ThemeProvider theme={theme}>
            <AppContext.Provider value={appContextValue}>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            {!isAuthenticated && <Redirect to={loginEndpoint} />}
                            {isAuthenticated && <MainScreen />}
                        </Route>
                        <Route exact path={loginEndpoint}>
                            <Login />
                        </Route>

                        <Route path={loginCallBackEndpoint}>
                            <LoginCallbackHandler />
                        </Route>
                        <Route path={logoutEndpoint}>
                            <Logout />
                        </Route>
                        <Route path={expiredTokenEndpoint}>
                            <Logout timeout />
                        </Route>
                        <Route path={tagGenerator}>
                            <TagGenerator />
                        </Route>
                    </Switch>
                </Router>
            </AppContext.Provider>
        </ThemeProvider>
    );
};
