import { Container } from '@material-ui/core';
import React from 'react';

interface IUnauthorized {
    error: string | null | undefined;
    errorDescription: string | null | undefined;
}

export const Unauthorized = ({ error, errorDescription }: IUnauthorized) => {
    return (
        <Container>
            <h4>An error occurred while logging in.</h4>
            <h5>Error</h5>
            <p>{error}</p>
            <h5>Description</h5> <p>{errorDescription}</p>
        </Container>
    );
};
