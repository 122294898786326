import { DropDownSelect } from '../../DropDownSelect/DropDownSelect';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

interface ITimezoneSelectorProps {
    timezone: string;
    setTimezone: (val: string) => void;
}

const AVAILABLE_TIMEZONES = [
    'America/New_York',
    'Europe/Paris',
    'Europe/Berlin',
    'Asia/Singapore',
    'Australia/Sydney',
    'Asia/Tokyo',
    'America/Guatemala',
    'US/Central',
    'US/Pacific',
];

export const TimezoneSelector = ({ timezone, setTimezone }: ITimezoneSelectorProps) => {
    const classes = useStyles();

    return (
        <DropDownSelect
            label={'Timezone'}
            options={AVAILABLE_TIMEZONES}
            onNewValue={setTimezone}
            className={classes.timeInput}
            value={timezone}
        />
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        timeInput: {
            margin: 10,
        },
    })
);
