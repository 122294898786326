import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { setSession } from '../../utils/Cookies';
import { Unauthorized } from '../Unauthorized';

export const LoginCallbackHandler = () => {
    const payload = queryString.parse(window.location.hash);
    const { setIsAuthenticated } = useAppContext();
    const { id_token, access_token, error, error_description } = payload;
    useEffect(() => {
        (async () => {
            if (error) {
                return;
            }
            if (id_token && access_token) {
                const idToken = id_token.toString();
                setIsAuthenticated(true);
                setSession(idToken, access_token.toString());
            } else {
                alert('Did not receive an expected callback payload. Failed to login.');
                console.error('Did not receive an expected callback payload. Failed to login.', payload);
            }

            window.location.replace('/');
        })();
    }, [id_token, access_token, error, payload, setIsAuthenticated]);

    if (error) {
        console.error('Failed to login.');
        console.error(error);
        console.error(error_description);

        return (
            <Unauthorized
                error={error.toString()}
                errorDescription={(!!error_description && error_description.toString()) || undefined}
            />
        );
    }

    return null;
};
