import { IAccount } from '../../../types/IAccount';
import { ConfigurationOptions } from 'aws-sdk/lib/config';
import { IInstanceRow } from '../../../types/IInstanceRow';
import { IEC2InstanceRow } from '../../../types/IEC2InstanceRow';
import AWS from 'aws-sdk';
import { getInstances as getEC2Instances } from '../../../utils/AWS/EC2';
import { getInstances as getRDSInstances } from '../../../utils/AWS/RDS';
import { IRDSInstanceRow } from '../../../types/IRDSInstanceRow';
import { SCHEDULE_TAG } from '../../../utils/SetInstanceScheduleTag';
import { WeeklySchedule } from '../../../types/WeeklySchedule';
import { ITag } from "../../../types/ITag";

interface IGetInstancesForTableFromAWS {
    account: IAccount | undefined;
    setLoadingMessage: (s: string | undefined) => void;
    role: ConfigurationOptions;
    service: 'rds' | 'ec2';
}
// TODO: This is where rows are getting set.
const compare = (a: ITag, b: ITag) => {
    return a.TagKey < b.TagKey ? -1 : 1;
}

export const GetInstancesForTableFromAWS = async ({
    account,
    setLoadingMessage,
    role,
    service,
}: IGetInstancesForTableFromAWS): Promise<IInstanceRow[]> => {
    const result: IEC2InstanceRow[] = [];

    return new Promise<IInstanceRow[]>(async (resolve, reject) => {
        for (const region of account?.Regions || []) {
            setLoadingMessage(`Retrieving ${service.toUpperCase()} instances for ${region}`);



            if (service === 'ec2') {
                const ec2 = new AWS.EC2({ ...role, ...{ region } });
                const instances = await getEC2Instances(ec2);
                const instanceRows = instances?.map(
                    (instance): IEC2InstanceRow => ({
                        AccountName: account?.Name || '',
                        AccountNumber: account?.Id || '',
                        Region: region,
                        Id: instance.InstanceId,
                        Type: instance.Type,
                        Name: instance.Name,
                        State: instance.State,
                        WeeklySchedule: instance.Tags?.filter((x) =>
                                x.TagKey.includes(SCHEDULE_TAG)).sort(compare).map((y) =>
                                    WeeklySchedule.fromTag(y, service))
                    })
                );

                if (!!instanceRows) result.push(...instanceRows);
            } else if (service === 'rds') {
                const rds = new AWS.RDS({ ...role, ...{ region } });
                const instances = await getRDSInstances(rds);

                const instanceRows = instances?.map(
                    (instance): IRDSInstanceRow => (
                        {
                        AccountName: account?.Name || '',
                        AccountNumber: account?.Id || '',
                        Region: region,
                        Type: instance.Type || '',
                        Id: instance.InstanceId,
                        Name: instance.Name,
                        State: instance.State,
                        WeeklySchedule: instance.Tags?.filter((x) => x.TagKey.includes(SCHEDULE_TAG)).sort(compare).map(
                            (y) => WeeklySchedule.fromTag(y, service)
                        ),

                    })
                );

                if (!!instanceRows) result.push(...instanceRows);
            }
        }

        resolve(result);
    });
};
