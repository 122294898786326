import { IEC2Instance } from '../../types/IEC2Instance';
import EC2, { DescribeInstancesRequest, DescribeInstancesResult, FilterList, Instance } from 'aws-sdk/clients/ec2';
import { AWSError } from 'aws-sdk';
import { ITag } from '../../types/ITag';

const MAX_INSTANCES_PER_SEARCH = 100;

const appendToResults = (results: IEC2Instance[], instances: Instance[] | undefined) =>
    instances?.forEach((instance) => {
        results.push({
            InstanceId: instance.InstanceId || 'N/A',
            Region: 'us-east-1',
            Tags: instance.Tags?.map(
                (tag): ITag => ({ TagKey: tag.Key || 'N/A', TagValue: tag.Value || '', Valid: 'false' })
            ),
            State: instance.State?.Name,
            Type: instance.InstanceType,
            Name: instance.Tags?.find((x) => x.Key?.toLowerCase() === 'name')?.Value || '',
        });
    });

export const getInstances = async (
    ec2: EC2,
    nextToken?: string,
    filter?: FilterList
): Promise<IEC2Instance[] | undefined> => {
    return new Promise<IEC2Instance[] | undefined>((resolve, reject) => {
        const result: IEC2Instance[] = [];
        const params: DescribeInstancesRequest = { MaxResults: MAX_INSTANCES_PER_SEARCH, NextToken: nextToken };

        ec2.describeInstances(
            { ...params, ...{ Filters: filter } },
            async (err: AWSError, data: DescribeInstancesResult) => {
                if (err) {
                    console.error('error getting ec2 instances');
                    console.error(err);
                    return resolve();
                }

                const allInstances: Instance[] | undefined = data.Reservations?.map(
                    (reservation) => reservation.Instances
                ).flat();

                appendToResults(result, allInstances);

                if (data.NextToken) {
                    const nextResults = await getInstances(ec2, data.NextToken, filter);
                    if (nextResults) result.push(...nextResults);
                }

                resolve(result);
            }
        );
    });
};
