import { IconButton, Tab, Tabs } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import logo from '../../bayer.png';
import { AvatarMenu } from './AvatarMenu';
import MenuIcon from '@material-ui/icons/Menu';
import { useAppContext } from '../../context/AppContext';

export const NavBar = () => {
    const classes = useStyles();
    const context = useAppContext();

    return (
        <AppBar position="sticky">
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => context.setAccountsDrawerOpen(true)}
                    edge="start"
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <img src={logo} className={classes.logo} alt={'Bayer Logo'} />
                <Typography className={classes.title} variant="h5" noWrap>
                    Scheduler Dashboard
                </Typography>
                <Tabs value={context.selectedService}>
                    <Tab label="EC2 Instances" onClick={() => context.setSelectedService('ec2')} value={'ec2'} />
                    <Tab label="RDS Instances" onClick={() => context.setSelectedService('rds')} value={'rds'} />
                </Tabs>
                <AvatarMenu />
            </Toolbar>
        </AppBar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        title: {
            flexGrow: 1,
            marginRight: '20px',
            display: 'none',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        logo: {
            height: 40,
            marginRight: 20,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
    })
);
