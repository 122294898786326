import AWS from 'aws-sdk';
import { ConfigurationOptions } from 'aws-sdk/lib/config';
import { ItemList } from 'aws-sdk/clients/dynamodb';

// TODO: Refactor AMCA and AWS2.0 to share logic.

export const readAccountsTable = async (tableName: string, role: ConfigurationOptions | undefined): Promise<ItemList|undefined> => {
    const db = new AWS.DynamoDB({ region: 'us-east-1', ...role });
    const response = await db.scan({ TableName: tableName }).promise()
    return response.Items;
};


export const validAccountId = /^[0-9]{12}$/;

export interface IGetAccountsParams {
    amcaSchedulerRole: ConfigurationOptions;
    cwid?: string
}
