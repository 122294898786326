import { AllDaysOfWeek, DaysOfWeek } from './DaysOfWeek';
import { ConvertJsonStringToRdsFriendlyJson, ConvertRDSFriendlyJsonToJsonString } from '../utils/AWS/RDS';
import { ITag } from './ITag';

const LEGACY_SCHEDULE_REGEX = /^(?:([A-z]{3,4})-)*(?:[0-9]{12}-)*((?:24[h]?)|[0-9]{1,4}-[0-9]{1,4})-((?:weekdays)|(?:weekends)|(?:alldays)|(?:[A-z]{3}-[A-z]{3}))(?:-patch-[A-z]*)*/;
const TWENTY_FOUR_HOUR_REGEX = /24[h]?/;
const ALLDAYS = 'alldays';
const WEEKENDS = 'weekends';
const WEEKDAYS = 'weekdays';

export enum ScheduleType {
    Normal = 'Normal',
    TwentyFourHour = '24hours',
    OnDemand = 'OnDemand',
}

export class WeeklySchedule {
    public Type: ScheduleType = ScheduleType.Normal;
    public Timezone: string | undefined;

    public Days: string | undefined;
    public StartTime: string | undefined;
    public StopTime: string | undefined;

    public IsAllDays: boolean | undefined;
    public StartDay: DaysOfWeek | undefined;
    public StopDay: DaysOfWeek | undefined;

    constructor(
        normal?: { days: DaysOfWeek[]; startTime: string; endTime: string; timezone: string },
        twentyFourHour?: { isAllDays: boolean; startDay?: DaysOfWeek; endDay?: DaysOfWeek; timezone?: string }
    ) {
        if (!!normal) {
            this.Type = ScheduleType.Normal;
            this.Days = normal.days.join();
            this.StartTime = normal.startTime;
            this.StopTime = normal.endTime;
            this.Timezone = normal.timezone;
        } else if (!!twentyFourHour) {
            this.Type = ScheduleType.TwentyFourHour;
            this.IsAllDays = twentyFourHour.isAllDays;
            this.StartDay = twentyFourHour.startDay;
            this.StopDay = twentyFourHour.endDay;
            this.Timezone = twentyFourHour.timezone;
        } else {
            this.Type = ScheduleType.OnDemand;
        }
    }

    static fromNormalConfig(timezone: string, days: DaysOfWeek[], startTime: string, endTime: string): WeeklySchedule {
        return new WeeklySchedule({ days, startTime, endTime, timezone });
    }

    static fromTwentyFourHourConfig(timezone: string, startDay: DaysOfWeek, endDay: DaysOfWeek) {
        return new WeeklySchedule(undefined, { startDay, endDay, isAllDays: false, timezone });
    }

    static fromAllDaysTwentyFourHourConfig() {
        return new WeeklySchedule(undefined, { isAllDays: true });
    }

    static onDemand() {
        return new WeeklySchedule();
    }

    static fromTag(tag: ITag | undefined, service: 'ec2' | 'rds'): WeeklySchedule | undefined {
        if (!tag) {
            return undefined;
        }
        try {
            const rawJsonText = service === 'ec2' ? tag.TagValue : ConvertRDSFriendlyJsonToJsonString(tag.TagValue);
            return JSON.parse(rawJsonText);
        } catch {
            return undefined;
        }
    }

    static fromLegacy(legacySchedule: string, timezone: string | undefined): WeeklySchedule | undefined {
        if (legacySchedule.toLowerCase().includes('ondemand')) {
            return WeeklySchedule.onDemand();
        }

        if (legacySchedule.includes('de-office-hours')) {
            return this.fromNormalConfig('Europe/Berlin', AllDaysOfWeek, '0700', '2000');
        }

        legacySchedule = legacySchedule.replace('24h-all-days', '24h-alldays');

        const regexMatches = LEGACY_SCHEDULE_REGEX.exec(legacySchedule);
        if (regexMatches == null) {
            console.error(`Schedule [${legacySchedule}] could not be parsed.`);
            return;
        }

        let timezoneToUse = timezone;
        const hours = regexMatches[2];
        const is24Hours = TWENTY_FOUR_HOUR_REGEX.test(hours);
        const days = regexMatches[3].replace(WEEKDAYS, 'mon-fri').replace(WEEKENDS, 'sat-sun');

        const timeSplit = hours.split('-');
        if (timeSplit.length !== 2 && !is24Hours) {
            console.error(`cannot split time ${hours}`);
            return;
        }

        if (days === ALLDAYS && is24Hours) return this.fromAllDaysTwentyFourHourConfig();

        if (!timezone) {
            timezoneToUse = regexMatches[1]
                ?.replace('cst', 'US/Central')
                .replace('cet', 'Europe/Berlin')
                .replace('pst', 'US/Pacific'); // cst is not recognized by moment tz
            console.error(
                `No timezone for ${legacySchedule} was found in config table, using ${timezoneToUse} as timezone`
            );
        }

        if (!timezoneToUse) {
            console.error(`Cannot find timezone string for ${legacySchedule}`);
            return;
        }

        if (days === ALLDAYS) return this.fromNormalConfig(timezoneToUse, AllDaysOfWeek, timeSplit[0], timeSplit[1]);

        const split = days.split('-');
        const startDay = split[0].toUpperCase() as DaysOfWeek;
        const endDay = split[1].toUpperCase() as DaysOfWeek;

        if (is24Hours) {
            return this.fromTwentyFourHourConfig(timezoneToUse, startDay, endDay);
        }

        const rangeOfDays = getDaysInRange(startDay, endDay);

        return this.fromNormalConfig(timezoneToUse, rangeOfDays, timeSplit[0], timeSplit[1]);
    }

    public toString() {
        return JSON.stringify(this);
    }

    public toRDSFriendlyString() {
        return ConvertJsonStringToRdsFriendlyJson(this.toString());
    }
}

const getDaysInRange = (startDay: DaysOfWeek, endDay: DaysOfWeek): DaysOfWeek[] => {
    const startDayIndex = AllDaysOfWeek.indexOf(startDay);
    const endDayIndex = AllDaysOfWeek.indexOf(endDay);

    let distance = endDayIndex - startDayIndex;
    if (endDayIndex < startDayIndex) {
        distance += AllDaysOfWeek.length;
    }

    const range = [];
    for (let index = startDayIndex; index <= startDayIndex + distance; index++) {
        range.push(AllDaysOfWeek[index % AllDaysOfWeek.length]);
    }

    return range;
};
