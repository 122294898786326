import { Box, createStyles, makeStyles, TextField } from '@material-ui/core';
import { TimezoneSelector } from '../TimezoneSelector';
import React from 'react';
import { DaySelection } from '../DaySelection';
import { DaysOfWeek } from '../../../types/DaysOfWeek';

interface INormalScheduleSettingsProps {
    timezone: string;
    setTimezone: (val: string) => void;
    startTime: string;
    setStartTime: (val: string) => void;
    stopTime: string;
    setStopTime: (val: string) => void;
    daysOfWeek: DaysOfWeek[];
    setDaysOfWeek: (val: DaysOfWeek[]) => void;
}
export const NormalScheduleSettings = ({
    timezone,
    setTimezone,
    startTime,
    setStartTime,
    stopTime,
    setStopTime,
    daysOfWeek,
    setDaysOfWeek,
}: INormalScheduleSettingsProps) => {
    const classes = useStyles();
    return (
        <Box display={'flex'} flexDirection={'column'} paddingLeft={5} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'row'}>
                <TimezoneSelector timezone={timezone} setTimezone={setTimezone} />
                <TextField
                    id="time"
                    label="Start"
                    value={startTime}
                    className={classes.timeInput}
                    onChange={(event) => setStartTime(event.target.value)}
                    type="time"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 1800, // 5 min
                    }}
                />

                <TextField
                    id="time"
                    label="Stop"
                    type="time"
                    className={classes.timeInput}
                    value={stopTime}
                    onChange={(event) => setStopTime(event.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        step: 1800, // 5 min
                    }}
                />
            </Box>
            <DaySelection selection={daysOfWeek} setSelection={setDaysOfWeek} />
        </Box>
    );
};

const useStyles = makeStyles((theme) =>
    createStyles({
        timeInput: {
            margin: 10,
        },
    })
);
