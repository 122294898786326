import { GetFederatedLoginUrl } from '../../utils/FederatedUrls';

export const Login = () => {
    (async () => {
        const loginUri = GetFederatedLoginUrl();
        window.location.replace(loginUri);
    })();

    return null;
};
