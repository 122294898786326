import React, { useEffect } from 'react';
import { NavBar } from '../components/NavBar';
import { getSession } from '../utils/Cookies';
import { loginEndpoint } from '../RouteNameConstants';
import { Redirect } from 'react-router-dom';
import { getAWS2Accounts } from '../utils/Bayer/AWS2Accounts'
import { useAppContext } from '../context/AppContext';
import { GetIdFromIdentityPoolToken, GetRole, GetRoleFromToken } from '../utils/AWS/STS';
import { AccountsDrawer } from '../components/AccountsDrawer';
import { SetAwsCredentials } from '../utils/SetAwsCredentials';
import { CreateScheduleModal } from '../components/CreateScheduleModal';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { InstanceTable } from '../components/InstanceTable';

const AMCA_SCHEDULER_ROLE = 'arn:aws:iam::974380800799:role/amca-scheduler';

export const MainScreen = () => {
    const session = getSession();
    const context = useAppContext();
    const getAccounts = () => {
        (async () => {
            if (!session) {
                return;
            }

            await SetAwsCredentials({
                idToken: session.idToken,
                setSchedulerAccountRole: context.setSchedulerAccountRol,
                setAmcaSchedulerRole: context.setAmcaSchedulerRole,
            });
            const id = await GetIdFromIdentityPoolToken(session.idToken);

            const config = {
                IdentityPoolId: 'us-east-1:7e500056-f86a-459f-9e93-28089e6650a1',
                Logins: {
                    'cognito-idp.us-east-1.amazonaws.com/us-east-1_ppTvZYJ8H': session.idToken,
                },
            };

            const schedulerReadRole = await GetRoleFromToken({
                identityId: id.IdentityId || '',
                Logins: config.Logins,
            });

            const amcaSchedulerRole = await GetRole({
                roleArn: AMCA_SCHEDULER_ROLE,
                roleToAssumeFrom: schedulerReadRole,
            });
            if (!amcaSchedulerRole) {
                console.error('Failed to get amca scheduler role');
                return;
            }
            const aws2accounts = await getAWS2Accounts({amcaSchedulerRole: amcaSchedulerRole, cwid: session.email})
            context.setAccounts(aws2accounts);
        })();
    };

    useEffect(getAccounts, []);

    if (!session || !session.idToken) {
        return <Redirect to={loginEndpoint} />;
    }

    return (
        <div className={'container-fluid'}>
            <NavBar />
            <AccountsDrawer />

            <div style={{ marginTop: 10 }}>
                <InstanceTable />
            </div>
            <CreateScheduleModal />
            <LoadingOverlay />
        </div>
    );
};
