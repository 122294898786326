import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Box, createMuiTheme, Drawer, ListItem, ListItemText, ThemeProvider, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { useAppContext } from '../../context/AppContext';
import { IAccount } from '../../types/IAccount';
import AutoSizer from 'react-virtualized-auto-sizer';
import { SearchBar } from './SearchBar';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
    },
});

export const AccountsDrawer = () => {
    const classes = useStyles();
    const context = useAppContext();
    const [searchText, setSearchText] = useState('');

    const accounts = context.accounts;
    const selectedAccount = context.selectedAccount;
    const setSelectedAccount = context.setSelectedAccount;
    const setAccountsDrawerOpen = context.setAccountsDrawerOpen;

    const listOfAccounts = useMemo(() => {
        const handleSelection = (account: IAccount) => {
            setSelectedAccount(account);
            setTimeout(() => setAccountsDrawerOpen(false), 500);
        };
        const accountsToDisplay =
            searchText.length > 0
                ? accounts.filter(
                      (account) => account.Name?.toLowerCase().includes(searchText) || account.Id?.includes(searchText)
                  )
                : accounts;

        const getAccountRow = (account: IAccount) => (
            <Box display={'flex'} flexDirection={'column'}>
                <Typography variant={'caption'}>{account.Name}</Typography>
                <Typography variant={'caption'} color={'textSecondary'}>
                    {account.Id}
                </Typography>
            </Box>
        );

        const Row = ({ index, style }: ListChildComponentProps) => {
            const account = accountsToDisplay[index];
            return (
                <ListItem
                    button
                    style={style}
                    key={account.Id}
                    onClick={() => handleSelection(account)}
                    selected={selectedAccount === account}
                >
                    <ListItemText primary={getAccountRow(account)} />
                </ListItem>
            );
        };

        return (
            <div className={classes.listContainer}>
                <AutoSizer>
                    {({ height, width }) => (
                        <List height={height} itemCount={accountsToDisplay.length} itemSize={70} width={width}>
                            {Row}
                        </List>
                    )}
                </AutoSizer>
            </div>
        );
    }, [accounts, searchText, classes, selectedAccount, setSelectedAccount, setAccountsDrawerOpen]);

    return (
        <ThemeProvider theme={darkTheme}>
            <Drawer
                keepMounted
                anchor={'left'}
                className={classes.drawer}
                open={context.accountsDrawerOpen}
                onClose={() => context.setAccountsDrawerOpen(false)}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <SearchBar searchText={searchText} setSearchText={setSearchText} />
                {listOfAccounts}
            </Drawer>
        </ThemeProvider>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        drawer: {
            width: 'auto',
            flexShrink: 0,
        },
        drawerPaper: {
            width: 'auto',
        },
        listContainer: {
            overflow: 'auto',
            height: '100%',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    })
);
