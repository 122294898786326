import { AWSError } from 'aws-sdk';
import RDS, { DBInstance, DBInstanceMessage, DescribeDBInstancesMessage } from 'aws-sdk/clients/rds';
import { IRDSInstance } from '../../types/IRDSInstance';
import { ITag } from '../../types/ITag';
import { FilterList } from 'aws-sdk/clients/rds';

const MAX_INSTANCES_PER_SEARCH = 100;

const appendToResults = (results: IRDSInstance[], instances: DBInstance[] | undefined) =>
    instances?.forEach((instance) => {
        results.push({
            Region: 'us-east-1',
            State: instance.DBInstanceStatus || 'N/A',
            Name: instance.DBInstanceIdentifier || 'N/A',
            Type: instance.DBInstanceClass || 'N/A',
            InstanceId: instance.DBInstanceArn || 'N/A',
        });
    });

export const getInstances = async (
    rds: RDS,
    nextToken?: string,
    filter?: FilterList
): Promise<IRDSInstance[] | undefined> => {
    return new Promise<IRDSInstance[] | undefined>((resolve, reject) => {
        const fetchInstanceResult: IRDSInstance[] = [];

        const params: DescribeDBInstancesMessage = {
            MaxRecords: MAX_INSTANCES_PER_SEARCH,
            Marker: nextToken,
            Filters: filter,
        };

        rds.describeDBInstances(params, async (err: AWSError, data: DBInstanceMessage) => {
            if (err) {
                console.error('Error when getting rds instances');
                console.error(err);
                return resolve();
            }

            appendToResults(fetchInstanceResult, data.DBInstances);

            if (data.Marker) {
                const nextResults = await getInstances(rds, data.Marker);
                if (nextResults) fetchInstanceResult.push(...nextResults);
            }

            const instancesWithTags = await Promise.all(
                fetchInstanceResult.map(
                    async (instance) =>
                        ({ ...instance, ...{ Tags: await fetchTagsForInstance(rds, instance) } } as IRDSInstance)
                )
            );

            resolve(instancesWithTags);
        });
    });
};

const fetchTagsForInstance = async (rds: RDS, instance: IRDSInstance): Promise<ITag[]> => {
    return new Promise<ITag[]>((resolve, reject) => {
        rds.listTagsForResource({ ResourceName: instance.InstanceId }, (err, data) => {
            if (err || !data) {
                console.error(err);
                return [];
            }
            resolve(data.TagList?.map((tag) => ({ TagKey: tag.Key, TagValue: tag.Value } as ITag)));
        });
    });
};

export const ConvertJsonStringToRdsFriendlyJson = (json: string): string => {
    return json.replace(/"/g, `=`).replace(/{/g, `+`).replace(/}/g, `-`).replace(/,/g, '.').replace(/\*/g, '@');
};

export const ConvertRDSFriendlyJsonToJsonString = (rdsJson: string): string => {
    return rdsJson.replace(/=/g, `"`).replace(/\+/g, `{`).replace(/-/g, `}`).replace(/\./g, ',').replace(/@/g, '*');
};
