import { createContext, useContext } from 'react';
import { IEC2InstanceRow } from '../types/IEC2InstanceRow';
import { IAccount } from '../types/IAccount';
import { ConfigurationOptions } from 'aws-sdk/lib/config';
import { IInstanceRow } from '../types/IInstanceRow';

export interface IAppContext {
    setIsAuthenticated: (isAuthenticated: boolean) => void;
    getUserName: () => string;
    schedulerAccountRole: ConfigurationOptions | undefined;
    setSchedulerAccountRol: (role: ConfigurationOptions | undefined) => void;
    amcaSchedulerRole: ConfigurationOptions | undefined;
    setAmcaSchedulerRole: (role: ConfigurationOptions | undefined) => void;
    setSelectedInstance: (instance: IEC2InstanceRow | undefined) => void;
    selectedInstance: IInstanceRow | undefined;
    createScheduleModalOpen: boolean;
    setCreateScheduleModalOpen: (val: boolean) => void;
    accounts: IAccount[];
    setAccounts: (accounts: IAccount[]) => void;
    selectedAccount: IAccount | undefined;
    setSelectedAccount: (account: IAccount | undefined) => void;
    accountsDrawerOpen: boolean;
    setAccountsDrawerOpen: (val: boolean) => void;
    isLoading: boolean;
    setIsLoading: (val: boolean) => void;
    loadingMessage: string | undefined;
    setLoadingMessage: (s: string | undefined) => void;
    refreshTableTrigger: any;
    triggerTableRefresh: () => void;
    selectedService: 'rds' | 'ec2';
    setSelectedService: (val: 'rds' | 'ec2') => void;
    setWeeklyScheduleIndex: (val: number) => void;
    weeklyScheduleIndex: number;

}

export const AppContext = createContext<IAppContext>({
    setIsAuthenticated: () => null,
    getUserName: () => '',
    schedulerAccountRole: undefined,
    setSchedulerAccountRol: () => null,
    amcaSchedulerRole: undefined,
    setAmcaSchedulerRole: () => null,
    setSelectedInstance: () => null,
    selectedInstance: undefined,
    createScheduleModalOpen: false,
    setCreateScheduleModalOpen: () => null,
    accounts: [],
    setAccounts: () => null,
    selectedAccount: undefined,
    setSelectedAccount: () => null,
    accountsDrawerOpen: false,
    setAccountsDrawerOpen: () => null,
    isLoading: true,
    setIsLoading: () => null,
    loadingMessage: '',
    setLoadingMessage: () => null,
    refreshTableTrigger: {},
    triggerTableRefresh: () => null,
    selectedService: 'ec2',
    setSelectedService: () => null,
    setWeeklyScheduleIndex: () => null,
    weeklyScheduleIndex: 0

});

export function useAppContext() {
    return useContext(AppContext);
}
