import SearchIcon from '@material-ui/icons/Search';
import { InputBase, Toolbar } from '@material-ui/core';
import React from 'react';
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';

interface IAccountsDrawerSearchBarParams {
    searchText: string;
    setSearchText: (s: string) => void;
}
export const SearchBar = ({ searchText, setSearchText }: IAccountsDrawerSearchBarParams) => {
    const classes = useStyles();
    return (
        <Toolbar className={classes.toolbar}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    inputRef={(input) => input && input.focus()}
                    placeholder="Account"
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    value={searchText}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(event) => setSearchText(event.target.value)}
                />
            </div>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingRight: 5,
            paddingLeft: 5,
        },

        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(1),
            marginLeft: 0,
            width: '100%',
        },
        searchIcon: {
            padding: theme.spacing(0, 0, 0, 1),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: '20ch',
            },
        },
    })
);
