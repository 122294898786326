export const getStateColor = (state?: string): string => {
    switch (state) {
        case 'stopped':
            return 'red';
        case 'running':
            return 'green';
        case 'available':
            return 'green';
        default:
            return 'black';
    }
};
