import {
    Box,
    Button,
    createMuiTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    ThemeProvider,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import { ScheduleType, WeeklySchedule } from '../../types/WeeklySchedule';
import { DaysOfWeek } from '../../types/DaysOfWeek';
import { SetInstanceScheduleTag } from '../../utils/SetInstanceScheduleTag';
import { ScheduleTypeSelection } from './ScheduleTypeSelector';
import { NormalScheduleSettings } from './NormalScheduleSettings';
import { TwentyFourHourScheduleSettings } from './TwentyFourHourScheduleSettings/TwentyFourHourScheduleSettings';

const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
    },
});

const DEFAULT_TIMEZONE: string = 'America/New_York';

export const CreateScheduleModal = () => {
    const context = useAppContext();
    const selectedInstance = context.selectedInstance;

    const [scheduleType, setScheduleType] = useState<ScheduleType>(ScheduleType.Normal);
    const [isAllDays, setIsAllDays] = useState(false);
    const [startTime, setStartTime] = useState<string>('07:30');
    const [stopTime, setStopTime] = useState<string>('18:30');
    const [timezone, setTimezone] = useState<string>(DEFAULT_TIMEZONE);
    const [startDay, setStartDay] = useState<DaysOfWeek>(DaysOfWeek.Monday);
    const [stopDay, setStopDay] = useState<DaysOfWeek>(DaysOfWeek.Friday);

    const [daysOfWeek, setDaysOfWeek] = useState<DaysOfWeek[]>([
        DaysOfWeek.Monday,
        DaysOfWeek.Tuesday,
        DaysOfWeek.Wednesday,
        DaysOfWeek.Thursday,
        DaysOfWeek.Friday,
    ]);

    const [isThereAValidSchedule, setIsThereAValidSchedule] = useState(false);

    useEffect(() => {
        let weeklySchedule;
        if(selectedInstance?.WeeklySchedule)
        {
            // @ts-ignore
            weeklySchedule = selectedInstance.WeeklySchedule[context.weeklyScheduleIndex];
        }
        else
        {
            weeklySchedule = undefined;
        }
        if (!weeklySchedule) {
            setScheduleType(ScheduleType.Normal);
            setTimezone(DEFAULT_TIMEZONE);

            setIsThereAValidSchedule(false);
            return;
        }

        setScheduleType(weeklySchedule.Type);
        setIsThereAValidSchedule(true);

        if (weeklySchedule.Type === ScheduleType.OnDemand) {
            return;
        }

        setTimezone(weeklySchedule.Timezone || DEFAULT_TIMEZONE);
        if (weeklySchedule.Type === ScheduleType.Normal) {
            setDaysOfWeek(weeklySchedule.Days?.split(',').map((day) => day as DaysOfWeek) || []);
            setStartTime(weeklySchedule.StartTime || '');
            setStopTime(weeklySchedule.StopTime || '');
        } else if (weeklySchedule.Type === ScheduleType.TwentyFourHour) {
            if (weeklySchedule.IsAllDays) {
                setIsAllDays(true);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInstance]);

    const close = () => {
        context.triggerTableRefresh();
        context.setSelectedInstance(undefined);
        context.setCreateScheduleModalOpen(false);
    };

    const updateSchedule = async (newSchedule?: WeeklySchedule) => {
        const instanceId = selectedInstance?.Id;
        if (!instanceId) {
            console.error('Do noy have instance id');
            return;
        }

        if (!context.selectedAccount) {
            console.error('No selected account');
            return;
        }

        if (!context.schedulerAccountRole) {
            console.error('no scheduler account role');
            return;
        }

        const success = await SetInstanceScheduleTag(
            context.selectedAccount,
            [{ instanceId, schedule: newSchedule }],
            context.selectedInstance?.Region || '',
            context.schedulerAccountRole,
            context.selectedService,
            context.weeklyScheduleIndex
        );
        if (success && !!selectedInstance) {
            // TODO: Clear up placeholder

            if(selectedInstance.WeeklySchedule){
                selectedInstance.WeeklySchedule[context.weeklyScheduleIndex] = newSchedule;
            }

            context.triggerTableRefresh();
            context.setSelectedInstance(undefined);
        }
        close();
    };

    const handleUpdateClick = async () => {
        if (scheduleType === ScheduleType.Normal) {
            await updateSchedule(WeeklySchedule.fromNormalConfig(timezone, daysOfWeek, startTime, stopTime));
        } else if (scheduleType === ScheduleType.TwentyFourHour && isAllDays) {
            await updateSchedule(WeeklySchedule.fromAllDaysTwentyFourHourConfig());
        } else if (scheduleType === ScheduleType.TwentyFourHour) {
            await updateSchedule(WeeklySchedule.fromTwentyFourHourConfig(timezone, startDay, stopDay));
        } else if (scheduleType === ScheduleType.OnDemand) {
            await updateSchedule(WeeklySchedule.onDemand());
        } else {
            await updateSchedule();
        }
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Dialog
                open={context.createScheduleModalOpen}
                onClose={close}
                aria-labelledby="form-dialog-title"
                maxWidth={'md'}
            >
                <DialogTitle id="form-dialog-title">Create Schedule</DialogTitle>
                <DialogContent>
                    <Box display={'flex'} flexDirection={'row'} padding={2}>
                        <ScheduleTypeSelection type={scheduleType} setType={setScheduleType} />
                        {scheduleType === ScheduleType.Normal && (
                            <NormalScheduleSettings
                                timezone={timezone}
                                setTimezone={setTimezone}
                                daysOfWeek={daysOfWeek}
                                setDaysOfWeek={setDaysOfWeek}
                                startTime={startTime}
                                setStartTime={setStartTime}
                                stopTime={stopTime}
                                setStopTime={setStopTime}
                            />
                        )}

                        {scheduleType === ScheduleType.TwentyFourHour && (
                            <TwentyFourHourScheduleSettings
                                timezone={timezone}
                                setTimezone={setTimezone}
                                isAllDays={isAllDays}
                                setIsAllDays={setIsAllDays}
                                startDay={startDay}
                                setStartDay={setStartDay}
                                stopDay={stopDay}
                                setStopDay={setStopDay}
                            />
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color={'primary'}>
                        Cancel
                    </Button>

                    {isThereAValidSchedule && (
                        <Button onClick={() => updateSchedule()} color={'secondary'}>
                            Delete
                        </Button>
                    )}

                    <Button onClick={handleUpdateClick} color="secondary">
                        {isThereAValidSchedule ? 'Update' : 'Create'}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};
