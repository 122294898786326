import { Options } from 'material-table';
import { IInstanceRow } from '../types/IInstanceRow';
import { IAccount } from '../types/IAccount';
const FALLBACK_PAGE_SIZE = 50;
const TABLE_PAGE_SIZE_TO_MAKE_LOADING_SPINNER_LOOK_GOOD = 10;
const USE_FIXED_TABLE = true;

export const getFilterTags = (values: string[]): any => {
    const labelKeys: any = {};
    values.forEach((value) => {
        if (value !== undefined && value.length > 0) {
            labelKeys[value.toString()] = value;
        }
    });
    return labelKeys;
};

// @ts-ignore
export const getTableOptions = (rows: IInstanceRow[] | undefined): Options<any> => {
    if (USE_FIXED_TABLE)
        return {
            search: false,
            paging: true,
            sorting: true,
            toolbar: false,
            filtering: true,
            pageSize: 10,
            showFirstLastPageButtons: false,
            pageSizeOptions: [10, 50, 100],
        };

    const pageSizeToUse =
        !!rows && rows?.length > 0
            ? Math.min(FALLBACK_PAGE_SIZE, rows?.length || 0)
            : TABLE_PAGE_SIZE_TO_MAKE_LOADING_SPINNER_LOOK_GOOD;

    return {
        search: false,
        paging: true,
        sorting: true,
        toolbar: false,
        filtering: true,
        pageSize: pageSizeToUse,
        showFirstLastPageButtons: false,
        pageSizeOptions: [pageSizeToUse, 10, 50, 100],
    };
};

export const sortAccounts = (a: IAccount, b: IAccount) => {
    if (!a.Name && !b.Name) {
        return 0;
    }
    if (!a.Name) {
        return -1;
    }
    if (!b.Name) {
        return 1;
    }
    if (a.Name > b.Name) {
        return 1;
    }
    if (a.Name < b.Name) {
        return -1;
    }

    return 0;
};
