import { Button } from '@material-ui/core';
import React from 'react';
import { useAppContext } from '../../context/AppContext';
import { IEC2InstanceRow } from '../../types/IEC2InstanceRow';

interface IBetaScheduleTagParams {
    Row: IEC2InstanceRow;
    Index: number;
}

export const ScheduleTagBadge = (params: IBetaScheduleTagParams) => {
    const context = useAppContext();

    if (!params.Row.WeeklySchedule || !params.Row.WeeklySchedule[params.Index]) {
        return (
            <Button
                variant={'outlined'}
                color={'primary'}
                size={'small'}
                onClick={() => {
                    context.setSelectedInstance(params.Row);
                    context.setWeeklyScheduleIndex(params.Index);
                    context.setCreateScheduleModalOpen(true);
                }}
            >
                Create
            </Button>
        );
    }
    return (
        <Button
            onClick={() => {
                context.setSelectedInstance(params.Row);
                context.setWeeklyScheduleIndex(params.Index);
                context.setCreateScheduleModalOpen(true);
            }}
        >
            Edit
        </Button>
    );
};
