import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { expiredTokenEndpoint } from '../RouteNameConstants';
// import { expiredTokenEndpoint } from "../RouteNameConstants";
const JwtTokenName = 'CidrTruthAuthToken';
const AdminUserGroupName = 'Admin';

export interface ISession {
    idToken: string;
    accessToken: string;
    username: string;
    email: string;
    role: string;
    sessionExpiration: Date;
}

interface IDecodedIdToken {
    name: string;
    exp: number;
    email: string;
    'custom:RoleClaims': string;
}

const parseIdToken = (idToken: string): IDecodedIdToken => {
    return jwtDecode<IDecodedIdToken>(idToken);
};

let callbackTimeout: NodeJS.Timeout | undefined;

const createAutoLogoutTimedCallback = (session: ISession): void => {
    if (!!callbackTimeout) {
        return;
    }

    const m = moment(session.sessionExpiration);
    const differenceInMilliseconds = m.diff(moment(new Date()), 'millisecond');

    callbackTimeout = setTimeout(() => window.location.replace(expiredTokenEndpoint), differenceInMilliseconds);
};

export const getIsAdmin = (): boolean => getSession()?.role?.includes(AdminUserGroupName) || false;

const getSessionExpirationDateTime = (numberOfSecondsSinceEpoch: number): Date =>
    new Date(numberOfSecondsSinceEpoch * 1000);

export const getSession = (): ISession | undefined => {
    const session = Cookies.getJSON(JwtTokenName);
    if (!!session) {
        createAutoLogoutTimedCallback(session);
    }

    return session;
};

export const getUserName = (): string => {
    const session = getSession();
    return (!!session && session.username) || '';
};

export const setSession = (idToken: string, accessToken: string) => {
    const parsed = parseIdToken(idToken);
    const { name, 'custom:RoleClaims': role, email, exp: expirationInNumberOfSecondsSinceEpoch } = parsed
    const session: ISession = {
        idToken,
        username: name,
        email,
        role,
        accessToken,
        sessionExpiration: getSessionExpirationDateTime(expirationInNumberOfSecondsSinceEpoch),
    };

    clearSession();
    createAutoLogoutTimedCallback(session);
    Cookies.set(JwtTokenName, session, {
        expires: session.sessionExpiration,
    });
};

export const clearSession = () => {
    if (callbackTimeout) {
        clearTimeout(callbackTimeout);
        callbackTimeout = undefined;
    }
    Cookies.remove(JwtTokenName);
};
