import { Avatar, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/AppContext';
import { logoutEndpoint } from '../../../RouteNameConstants';
import { getIsAdmin } from '../../../utils/Cookies';
import { getInitials } from '../../../utils/Username';

export const AvatarMenu = () => {
    const [menuAnchorElement, setMenuAnchorElement] = useState<(EventTarget & HTMLButtonElement) | undefined>();
    const appContext = useAppContext();
    const classes = useStyles();

    return (
        <>
            <Button
                aria-controls="avatar-menu"
                aria-haspopup="true"
                onClick={(event) => setMenuAnchorElement(event.currentTarget)}
            >
                <Avatar>
                    <Typography>{getInitials(appContext.getUserName())}</Typography>
                </Avatar>
            </Button>
            <Menu
                open={Boolean(menuAnchorElement)}
                anchorReference={'anchorEl'}
                anchorEl={menuAnchorElement}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={() => setMenuAnchorElement(undefined)}
                className={classes.menuBackground}
                MenuListProps={{ style: { paddingLeft: 10, paddingRight: 10 } }}
            >
                <Typography variant={'subtitle1'} gutterBottom>
                    {appContext.getUserName()}
                </Typography>
                <Typography variant={'caption'}>Role: {getIsAdmin() ? 'Admin' : 'User'} </Typography>
                <MenuItem>
                    <Link to={logoutEndpoint}>Logout</Link>
                </MenuItem>
            </Menu>
        </>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuBackground: {
            '& .MuiPaper-root': {
                backgroundColor: theme.palette.grey[500],
                color: theme.palette.common.white,
            },
        },
    })
);
