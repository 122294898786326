import AWS from 'aws-sdk';
import { GetRole, GetRoleFromToken } from './AWS/STS';
import { ConfigurationOptions } from 'aws-sdk/lib/config';

const AMCA_SCHEDULER_ROLE = 'arn:aws:iam::974380800799:role/amca-scheduler';

interface ISetAwsCredentialsParams {
    idToken: string;
    setAmcaSchedulerRole: (role: ConfigurationOptions | undefined) => void;
    setSchedulerAccountRole: (role: ConfigurationOptions | undefined) => void;
}
export const SetAwsCredentials = async ({
    idToken,
    setAmcaSchedulerRole,
    setSchedulerAccountRole,
}: ISetAwsCredentialsParams) => {
    const cognitoIdentity = new AWS.CognitoIdentity({ region: 'us-east-1' });

    AWS.config.region = 'us-east-1';
    const config = {
        IdentityPoolId: 'us-east-1:7e500056-f86a-459f-9e93-28089e6650a1',
        Logins: {
            'cognito-idp.us-east-1.amazonaws.com/us-east-1_ppTvZYJ8H': idToken,
        },
    };

    return new Promise(async (resolve, reject) => {
        cognitoIdentity.getId(config, async (err, data) => {
            if (err || !data.IdentityId) {
                console.error('an error occurred while getting id');
                console.error(data);
                console.error(err);
                resolve();
                return;
            }
            const schedulerReadRole = await GetRoleFromToken({ identityId: data.IdentityId, Logins: config.Logins });
            if (schedulerReadRole === undefined) {
                return reject();
            }

            AWS.config.credentials = new AWS.Credentials({
                accessKeyId: schedulerReadRole.credentials?.accessKeyId || '',
                sessionToken: schedulerReadRole.credentials?.sessionToken,
                secretAccessKey: schedulerReadRole.credentials?.secretAccessKey || '',
            });

            const amcaSchedulerRole = await GetRole({
                roleArn: AMCA_SCHEDULER_ROLE,
                roleToAssumeFrom: schedulerReadRole,
            });

            setSchedulerAccountRole(schedulerReadRole);
            setAmcaSchedulerRole(amcaSchedulerRole);

            resolve();
        });
    });
};
