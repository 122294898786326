import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../context/AppContext';
import { loginEndpoint } from '../../RouteNameConstants';
import { clearSession } from '../../utils/Cookies';

export const Logout = ({ timeout }: { timeout?: boolean }) => {
    const { setIsAuthenticated } = useAppContext();
    useEffect(() => {
        setIsAuthenticated(false);
        clearSession();
    }, [setIsAuthenticated]);

    return (
        <Container>
            {timeout && <p>Your session has expired.</p>}
            <p>You have been logged out.</p>
            <Link to={loginEndpoint}>Sign in</Link>
        </Container>
    );
};
