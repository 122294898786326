import React from 'react';
import { DaysOfWeek } from '../../../types/DaysOfWeek';
import { Button, ButtonGroup } from '@material-ui/core';

export interface IDaySelectionProps {
    selection: DaysOfWeek[];
    setSelection: (val: DaysOfWeek[]) => void;
    multiSelect?: boolean;
    isStop?: boolean;
    disabled?: boolean;
}

const getEnum = (day: string) => {
    // @ts-ignore
    return DaysOfWeek[day];
};

export const DaySelection = ({ selection, setSelection, multiSelect = true, isStop, disabled }: IDaySelectionProps) => {
    const toggleDay = (dayToToggle: DaysOfWeek) => {
        const isSelected = selection.includes(dayToToggle);

        if (isSelected && multiSelect) {
            setSelection(selection.filter((day) => day !== dayToToggle));
        } else {
            const newSelection = multiSelect ? [...selection] : [];
            newSelection.push(dayToToggle);
            setSelection(newSelection);
        }
    };

    return (
        <ButtonGroup color={isStop ? 'secondary' : 'primary'}>
            {Object.keys(DaysOfWeek).map((day) => (
                <Button
                    disabled={disabled}
                    key={day}
                    variant={selection.includes(getEnum(day)) ? 'contained' : 'outlined'}
                    onClick={() => toggleDay(getEnum(day))}
                >
                    {getEnum(day)}
                </Button>
            ))}
        </ButtonGroup>
    );
};
