import { MenuItem, StandardTextFieldProps, TextField } from '@material-ui/core';
import React from 'react';

interface IDropDownSelectProps extends StandardTextFieldProps {
    label: string;
    value?: any;
    options: any[];
    onNewValue: (value: string) => void;
}
export const DropDownSelect = ({ label, value, options, onNewValue, ...props }: IDropDownSelectProps) => {
    return (
        <TextField select label={label} value={value} onChange={(event) => onNewValue(event.target.value)} {...props}>
            {options.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            ))}
        </TextField>
    );
};
