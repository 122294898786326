import { Box } from '@material-ui/core';
import { TimezoneSelector } from '../TimezoneSelector/TimezoneSelector';
import React from 'react';
import { DaySelection } from '../DaySelection/DaySelection';
import { DaysOfWeek } from '../../../types/DaysOfWeek';
import { ToggleSwitch } from '../../ToggleSwitch/ToggleSwitch';

interface ITwentyFourHourScheduleSettingsProps {
    timezone: string;
    setTimezone: (val: string) => void;
    isAllDays: boolean;
    setIsAllDays: (val: boolean) => void;
    startDay: DaysOfWeek;
    setStartDay: (val: DaysOfWeek) => void;
    stopDay: DaysOfWeek;
    setStopDay: (val: DaysOfWeek) => void;
}
export const TwentyFourHourScheduleSettings = ({
    timezone,
    setTimezone,
    isAllDays,
    setIsAllDays,
    startDay,
    setStartDay,
    stopDay,
    setStopDay,
}: ITwentyFourHourScheduleSettingsProps) => {
    return (
        <Box display={'flex'} flexDirection={'column'} paddingLeft={5} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'row'}>
                <TimezoneSelector timezone={timezone} setTimezone={setTimezone} />
                <ToggleSwitch value={isAllDays} setValue={setIsAllDays} label={'All Days'} />
            </Box>

            <DaySelection
                selection={[startDay]}
                setSelection={(val) => setStartDay(val[0])}
                multiSelect={false}
                disabled={isAllDays}
            />
            <DaySelection
                selection={[stopDay]}
                setSelection={(val) => setStopDay(val[0])}
                multiSelect={false}
                isStop
                disabled={isAllDays}
            />
        </Box>
    );
};
